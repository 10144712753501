@tailwind base;
@tailwind components;
@tailwind utilities;

/* Customizations */
body {
  min-width: 320px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

@media (min-width: 768px) {
  body {
    overflow: hidden;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
}

.mainContent {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
@media (min-width: 768px) {
  .mainContent {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .mainContent {
    display: flex;
  }
}
@media (prefers-color-scheme: dark) {
  .mainContent {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
}

input.custom-toggle:checked ~ .dot {
  transform: translateX(100%);
  background-color: rgba(234, 88, 12, 1);
}
.no-underline {
  text-decoration: none !important;
}
.facet {
  line-height: 1.75rem;
}
.facet button.only {
  visibility: hidden;
}
.facet:hover button.only,
.facet:focus-within button.only {
  visibility: visible;
}
.reddit-comment ul,
.reddit-comment ol {
  padding-left: 2rem;
  margin: 0.5rem 0;
}
.reddit-comment ul {
  list-style-type: disc;
}
.reddit-comment ol {
  list-style-type: decimal;
}
.reddit-comment table {
  margin: 0.5rem 0;
}
.reddit-comment table th,
.reddit-comment table td {
  border: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
}
.reddit-comment blockquote {
  border-left: 2px solid #9ca3af;
  margin: 0.25rem 0.5rem;
  padding: 0.25rem 0.5rem;
  color: #9ca3af;
}
.reddit-comment blockquote ul,
.reddit-comment blockquote ol {
  margin: 0;
}
.reddit-comment blockquote + blockquote {
  margin-top: -0.25rem;
}
.loader {
  border-top-color: rgb(2, 132, 199);
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
@media (prefers-color-scheme: dark) {
  .loader {
    border-top-color: rgb(8, 145, 178);
  }
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.customize-date-range .rdrCalendarWrapper {
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.customize-date-range .rdrDateDisplayWrapper {
  border-radius: 0.25rem;
}
.customize-date-range .rdrDateDisplay {
  margin: 0.75rem;
}
.customize-date-range .rdrMonthAndYearWrapper,
.customize-date-range .rdrMonths {
  font-size: 0.75rem;
}
.customize-date-range .rdrMonthAndYearWrapper {
  padding: 0.25rem 0;
  height: auto;
}
.customize-date-range .rdrMonth {
  padding: 0 0.75rem 0.5rem 0.75rem;
}
.customize-date-range .rdrMonthAndYearPickers select {
  padding: 0.5rem 1.5rem 0.5rem 0.75rem;
}
.customize-date-range .rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0.75rem;
}
.customize-date-range .rdrCalendarWrapper.rdrDateRangeWrapper,
.customize-date-range .rdrMonth {
  width: 100%;
}
